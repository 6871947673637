import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";
import { useAuth } from "../auth";
import { NavLink } from "../components/Link/NavLink";
import { Message } from "../i18n";

const HeaderHorizontal = () => {
  const { premiumAccess, premiumCreator } = useAuth();

  return (
    <header
      className="topnavbar-wrapper"
      style={{
        fontFamily: "'colaborateregular', sans-serif",
        fontSize: "1.2em",
      }}
    >
      <nav role="navigation" className="navbar topnavbar">
        <div
          style={{ maxWidth: 1120, marginLeft: "auto", marginRight: "auto" }}
        >
          <div className="navbar-header">
            <Link to="/exercises" className="navbar-brand">
              <div className="brand-logo">
                <div>
                  <img src={logo} alt="App Logo" />
                </div>
              </div>
            </Link>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <ul className="nav navbar-nav">
              <NavLink to="/exercises" wrapper={NavBarLink}>
                Lista de Exercícios
              </NavLink>
              {(premiumAccess || premiumCreator) && (
                <NavLink to="/assessments" wrapper={NavBarLink}>
                  Avaliações
                </NavLink>
              )}
            </ul>
            <UserMenu />
          </div>
        </div>
        <div className="navbar-collapse collapse"></div>
      </nav>
    </header>
  );
};

const NavBarLink = ({ children, active = false }) => (
  <li role="presentation" className={active ? "active" : ""}>
    {children}
  </li>
);

const UserMenu = () => {
  const { user, premiumAccess, loginURL, logout } = useAuth();

  const becomePremium = () => {
    return (
      <li>
        <a
          href="https://xlung.net/orders"
          target="_blank"
          rel="noopener noreferrer"
        >
          Seja Premium
        </a>
      </li>
    );
  };

  const renderUserMenu = () => {
    if (user) {
      return (
        <li className={`dropdown`}>
          <a
            className="dropdown-toggle"
            data-toggle="dropdown"
            data-target="#"
            style={{ cursor: "pointer" }}
          >
            <em
              className="fa fa-user-circle-o fa-lg"
              style={{ marginRight: "5px" }}
            />
            {user.email}
            &nbsp;
            <span className="caret"></span>
          </a>
          <ul className="dropdown-menu" style={{ width: "100%" }}>
            <li>
              <a
                href="https://goo.gl/forms/ZGXPGM8QcRtd09yz2"
                target="_blank"
                rel="noreferrer noopener"
              >
                Dê sua opinião
              </a>
            </li>
            <li>
              <a onClick={logout} style={{ cursor: "pointer" }}>
                Sair
              </a>
            </li>
          </ul>
        </li>
      );
    }

    return (
      <li>
        <a href={loginURL}>
          <em
            className="fa fa-user-circle-o fa-lg"
            style={{ marginRight: "5px" }}
          />
          {Message("header.clientNameOrSignIn")}
        </a>
      </li>
    );
  };

  return (
    <ul className="nav navbar-nav">
      {!premiumAccess && becomePremium()}
      {renderUserMenu()}
    </ul>
  );
};

export default HeaderHorizontal;
