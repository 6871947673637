import "./assets/stylesheets/vendor.scss";
import "./assets/stylesheets/app.scss";

import "react-bootstrap-sweetalert/dist/css/animations.css";
import "animate.css/animate.css";
import "bootstrap-switch/dist/css/bootstrap3/bootstrap-switch.css";

import "./proptypes-loader";
import "./jquery-loader";
import "bootstrap/dist/js/bootstrap";
import "./modernizr";

import { BrowserRouter, useRoutes } from "react-router-dom";
import { routes } from "./routes";
import I18nProvider from "./i18n/I18nProvider";
import { ModalDialogProvider } from "./components/ModalDialog";
import AuthProvider from "./auth";
import { ConfigProvider } from "./config/Config";
import { BrowserView, MobileView } from "react-device-detect";
import { ContentWrapper } from "./layout/ContentWrapper";
import { Panel } from "react-bootstrap";

export const App = () => {
  return (
    <BrowserRouter>
      <ConfigProvider>
        <I18nProvider>
          <AuthProvider>
            <ModalDialogProvider>
              <>
                <BrowserView>
                  <Routes />
                </BrowserView>
                <MobileView>
                  <ContentWrapper
                    hideBreadcrumbs
                    style={{ minHeight: "100%", height: "100%" }}
                  >
                    <Panel
                      style={{
                        fontFamily: "'colaborateregular', sans-serif",
                        fontSize: "1.2em",
                        height: "100%",
                        minHeight: "100%",
                      }}
                    >
                      Este sistema não é compatível com dispositivos móveis.
                      Tente novamente com o navegador atualizado no desktop.
                    </Panel>
                  </ContentWrapper>
                </MobileView>
              </>
            </ModalDialogProvider>
          </AuthProvider>
        </I18nProvider>
      </ConfigProvider>
    </BrowserRouter>
  );
};

function Routes() {
  return useRoutes(routes);
}
